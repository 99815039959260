<template>
  <div class="feature-media-box">
    <div class="bg-fade"></div>
    <div class="content">
      <div class="item" v-for="item in imgList" :key="item.img">
        <img :src="item.img" />
      </div>
    </div>
    <div class="content">
      <div class="item" v-for="item in imgList" :key="item.img">
        <img :src="item.img" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      imgList: [
        {
          img: `${process.env.VUE_APP_STORE_OSS_URL}/home/FM1.png`,
        },
        {
          img: `${process.env.VUE_APP_STORE_OSS_URL}/home/FM2.png`,
        },
        {
          img: `${process.env.VUE_APP_STORE_OSS_URL}/home/FM3.png`,
        },
        {
          img: `${process.env.VUE_APP_STORE_OSS_URL}/home/FM4.png`,
        },
        {
          img: `${process.env.VUE_APP_STORE_OSS_URL}/home/FM5.png`,
        },
        {
          img: `${process.env.VUE_APP_STORE_OSS_URL}/home/FM6.png`,
        },
        {
          img: `${process.env.VUE_APP_STORE_OSS_URL}/home/FM7.png`,
        },
        {
          img: `${process.env.VUE_APP_STORE_OSS_URL}/home/FM8.png`,
        },
        {
          img: `${process.env.VUE_APP_STORE_OSS_URL}/home/FM9.png`,
        },
      ],
    };
  },
  methods: {},
};
</script>

<style lang="less" scoped>
@-webkit-keyframes slide-data {
  0% {
    transform: translateZ(0);
  }
  to {
    transform: translate3d(100%, 0, 0);
  }
}
@keyframes slide-data {
  0% {
    transform: translateZ(0);
  }
  to {
    transform: translate3d(100%, 0, 0);
  }
}
.feature-media-box {
  max-width: 1228px;
  overflow: hidden;
  margin: 82px auto 0;
  height: 148px;
  background: #f5f6fa;
  padding: 20px 0;
  box-sizing: border-box;
  position: relative;
  display: flex;
  flex-direction: row-reverse;
  @media screen and( max-width:960px) {
    height: 100px;
    padding: 20px 15px;
    margin-top: 60px;
  }
  .bg-fade {
    background: linear-gradient(
      90deg,
      #fff,
      hsla(0, 0%, 100%, 0) 20%,
      hsla(0, 0%, 100%, 0) 80%,
      #fff
    );
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 1;
  }
  .content {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    //justify-content:space-evenly;
    //-webkit-justify-content:space-evenly;
    transform: translateZ(0);
    -webkit-animation: slide-data 66s linear infinite;
    animation: slide-data 66s linear infinite;
    .item {
      padding-right: 32px;
      overflow: hidden;
      height: 108px;
      min-width: 140px;
      box-sizing: border-box;
      @media screen and( max-width:960px) {
        padding-right: 10px;
        height: 60px;
        min-width: 70px;
      }
      img {
        width: 108px;
        height: 108px;
        overflow: hidden;
        @media screen and( max-width:960px) {
          width: 60px;
          height: 60px;
        }
      }
    }
  }
}
</style>
