<template>
  <div class="quick-easy-box">
    <div class="quick-left loading-bg">
      <img :lazyLoadSrc="quickImg" v-lazy-load-img />
    </div>
    <div class="quick-right">
      <div class="sub-title">
        <div>{{ $t("home.quickEasyModule.quickEasy") }}</div>
        <div class="line"></div>
      </div>
      <div class="title">
        <div v-html="$t('home.quickEasyModule.title1')"></div>
        <div v-html="$t('home.quickEasyModule.title2')"></div>
      </div>
      <div class="info" v-html="$t('home.quickEasyModule.info')"></div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      quickImg: `${process.env.VUE_APP_STORE_OSS_URL}/home/quick.png`,
    };
  },
  methods: {},
};
</script>

<style lang="less" scoped>
/deep/.gradient-text {
  background-image: @gradient-text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
/deep/.strong-text {
  font-family: @font-family-bold;
}
.quick-easy-box {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
  color: #000000;
  @media screen and( max-width:960px) {
    margin-top: 40px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }
  .quick-left {
    max-width: 770px;
    max-height: 634px;
    width: 770px;
    height: 634px;
    @media screen and( max-width:960px) {
      max-width: 100%;
      height: auto;
      max-height: fit-content;
      margin-bottom: 35px;
      padding-right: 15px;
      box-sizing: border-box;
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
  .quick-right {
    flex: 1;
    margin-left: 165px;
    @media screen and( max-width:960px) {
      margin: 0;
      padding: 0 15px;
    }
    .sub-title {
      font-size: 24px;
      line-height: 28px;
      color: #000000;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin-bottom: 28px;
      @media screen and( max-width:960px) {
        font-size: 20px;
        line-height: 28px;
        margin-bottom: 20px;
      }
      .line {
        margin-left: 28px;
        height: 1px;
        width: 226px;
        background: #000000;
        @media screen and( max-width:960px) {
          margin-left: 18px;
          width: 175px;
        }
      }
    }
    .title {
      font-size: 48px;
      line-height: 60px;
      font-family: @font-family-bold;
      margin-bottom: 28px;
      @media screen and( max-width:960px) {
        font-size: 30px;
        line-height: 38px;
        margin-bottom: 18px;
      }
    }
    .info {
      font-size: 20px;
      line-height: 30px;
      @media screen and( max-width:960px) {
        font-size: 16px;
        line-height: 26px;
      }
    }
  }
}
</style>
