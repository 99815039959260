<template>
  <div class="payment">
    <div class="pay-left">
      <div class="title">
        <span>{{ $t("payment.title") }}</span>
        <div class="line"></div>
      </div>
      <div class="des">
        <span>{{ $t("payment.des1") }}</span>
        <span class="brand">{{ $t("payment.des2") }}</span>
        <span>{{ $t("payment.des3") }}</span>
        <span class="brand">{{ $t("payment.des4") }}</span>
        <span>{{ $t("payment.des5") }}</span>
      </div>
      <img
        :lazyLoadSrc="shipperIcon"
        src="../../../assets/img/loading.gif"
        v-lazy-load-img
        alt=""
        class="shipper-img"
      />
      <img
        :lazyLoadSrc="moShipperIcon"
        src="../../../assets/img/loading.gif"
        v-lazy-load-img
        alt=""
        class="moshipper-img"
      />
    </div>
    <div class="pay-right">
      <img
        :lazyLoadSrc="paymentBg"
        alt=""
        src="../../../assets/img/loading.gif"
        v-lazy-load-img
        class="desktop-bg"
      />
      <img
        :lazyLoadSrc="paymentMoBg"
        alt=""
        src="../../../assets/img/loading.gif"
        v-lazy-load-img
        class="mobilepay-bg"
      />
      <img
        :src="paymentAbsoluteIcon"
        alt=""
        class="payment-absolute"
        ref="paymentAbsolute"
      />
      <img
        :src="courierAbsoluteIcon"
        alt=""
        class="courier-absolute"
        ref="courierAbsolute"
      />
    </div>
  </div>
</template>

<script>
import untils from "./until";
export default {
  mixins: [untils],
  data() {
    return {
      shipperIcon: `${process.env.VUE_APP_STORE_OSS_URL}/home/shipper.png`,
      moShipperIcon: `${process.env.VUE_APP_STORE_OSS_URL}/home/moShipper.png`,
      paymentBg: `${process.env.VUE_APP_STORE_OSS_URL}/home/payment-bg.png`,
      paymentMoBg: `${process.env.VUE_APP_STORE_OSS_URL}/home/payment-mobg.png`,
      paymentAbsoluteIcon: `${process.env.VUE_APP_STORE_OSS_URL}/home/payment-absolute.png`,
      courierAbsoluteIcon: `${process.env.VUE_APP_STORE_OSS_URL}/home/courier-absolute.png`,
    };
  },
  mounted() {
    const payment = this.$refs.paymentAbsolute;
    const courier = this.$refs.courierAbsolute;
    payment && this.initParallax(this.$el, payment);
    courier && this.initParallax(this.$el, courier);
  },
  methods: {},
};
</script>

<style lang="less" scoped>
.payment {
  display: flex;
  justify-content: space-between;
  background: #ccefff;
  padding-top: 160px;
  .pay-left {
    margin-left: 106px;
    max-width: 780px;
    .title {
      display: flex;
      align-items: center;
      span {
        font-family: @font-family-regular;
        font-style: normal;
        font-weight: normal;
        font-size: 24px;
        line-height: 28px;
        color: #000000;
      }
      .line {
        width: 226px;
        background: #000000;
        height: 1px;
        margin-left: 28px;
      }
    }
    .des {
      margin-top: 28px;
      min-width: 534px;
      span {
        font-family: @font-family-bold;
        font-style: normal;
        font-weight: bold;
        font-size: 48px;
        line-height: 60px;
        letter-spacing: -0.02em;
        color: #000000;
      }
      .brand {
        background-image: @gradient-text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }
    .shipper-img {
      margin-top: 42px;
    }
    .moshipper-img {
      display: none;
    }
  }
  .pay-right {
    position: relative;
    .shipper-img {
      display: none;
    }
    .mobilepay-bg {
      display: none;
    }
    .payment-absolute {
      position: absolute;
      right: 500px;
      top: -96px;
    }
    .courier-absolute {
      position: absolute;
      right: 320px;
      bottom: 106px;
    }
  }
  @media screen and (max-width: 960px) {
    flex-direction: column-reverse;
    align-items: flex-end;
    padding-left: 15px;
    padding-bottom: 82px;
    .pay-left {
      padding-right: 15px;
      margin-left: 0;
      .title {
        margin-top: 65px;
        span {
          font-size: 20px;
          line-height: 28px;
        }
        .line {
          width: 95px;
          margin-left: 18px;
        }
      }
      .des {
        min-width: 332px;
        span {
          font-size: 30px;
          line-height: 38px;
        }
      }
      .shipper-img {
        display: none;
      }
      .moshipper-img {
        display: block;
        margin-top: 30px;
      }
    }
    .pay-right {
      .desktop-bg {
        display: none;
      }
      .mobilepay-bg {
        display: block;
      }
      .payment-absolute {
        width: 96px;
        position: absolute;
        right: 0;
        left: 15px;
        top: -13px;
      }
      .courier-absolute {
        width: 187px;
        position: absolute;
        left: -44px;
        bottom: 0px;
      }
    }
  }
}
</style>
