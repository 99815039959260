export default {
  methods: {
    initParallax(t, e) {
      const n = this;
      window.addEventListener("scroll", function () {
        n.throttle(n.parallax(t, e), 14);
      });
    },
    throttle(t, e) {
      let n = Date.now();
      return function () {
        n + e - Date.now() < 0 && (t(), (n = Date.now()));
      };
    },
    parallax(t, e) {
      const n =
        0.2 *
          (e.getBoundingClientRect().top -
            window.innerHeight +
            t.getBoundingClientRect().height *
              (window.innerWidth <= 734 ? 0.5 : 1)) +
        "px";
      e.style.transform = "translateY(" + n + ")";
    },
  },
};
