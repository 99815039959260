<template>
  <div class="home-wrap">
    <Banner />
    <Designer />
    <FeaturedMedia />
    <Payment />
    <DomainPixel />
    <AnalyticsData />
    <QuickEasy />
    <CreateDestyStore />
    <Support />
  </div>
</template>

<script>
import Support from "../../components/Support";
import Banner from "./components/Banner.vue";
import FeaturedMedia from "./components/FeaturedMedia";
import Designer from "./components/StoreDesigner.vue";
import Payment from "./components/Payment.vue";
import CreateDestyStore from "./components/CreateDestyStore";
import QuickEasy from "./components/QuickEasy";
import AnalyticsData from "./components/AnalyticsData";
import DomainPixel from "./components/DomainPixel";

export default {
  components: {
    Banner,
    Designer,
    FeaturedMedia,
    Payment,
    DomainPixel,
    AnalyticsData,
    QuickEasy,
    CreateDestyStore,
    Support,
  },
  data() {
    return {};
  },
  created() {
    this.$logEvent("ds-s:view desty store homepage", {}, true);
  },
};
</script>
<style lang="less" scoped>
.home-wrap {
  @media screen and (max-width: 960px) {
    width: 100%;
  }
}
::v-deep .loading-bg {
  min-width: 50px;
  min-height: 50px;
}
</style>
