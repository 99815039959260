<template>
  <div class="store-design">
    <div class="left">
      <img :src="designBg" alt="" class="desktop-bg" />
      <img :src="moDesignBg" alt="" class="mobile-bg" />
      <img :src="absoluteImg" alt="" class="absolute-img" ref="foreground" />
    </div>
    <div class="right">
      <div class="title">
        <span>{{ $t("design.title") }}</span>
        <div class="line"></div>
      </div>
      <div class="des">
        <span>{{ $t("design.des") }}</span>
        <span class="brand"> {{ $t("design.brandidentity") }}</span>
        <span> {{ $t("design.des2") }}</span>
      </div>
      <div class="openyour">
        {{ $t("design.open") }}
      </div>
      <div class="view-gellery" @click="openGellery">
        <div class="hover-right icon">
          <span>{{ $t("design.viewGellery") }}</span>
          <svg-icon class-name="iconRight" icon-class="arrow-right"></svg-icon>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import untils from "./until";
export default {
  mixins: [untils],
  data() {
    return {
      designBg: `${process.env.VUE_APP_STORE_OSS_URL}/home/design-bg.webp`,
      moDesignBg: `${process.env.VUE_APP_STORE_OSS_URL}/home/design-mobg.webp`,
      absoluteImg: `${process.env.VUE_APP_STORE_OSS_URL}/home/design-absolute.png`,
    };
  },
  mounted() {
    const t = this.$refs.foreground;
    t && this.initParallax(this.$el, t);
  },
  methods: {
    openGellery() {
      this.$router.push("/gallery");
      this.$logEvent("ds-s:click view gallery ");
    },
  },
};
</script>

<style lang="less" scoped>
.store-design {
  margin-top: 100px;
  display: flex;
  justify-content: space-between;
  .left {
    position: relative;
    width: 720px;
    .mobile-bg {
      display: none;
    }
    .absolute-img {
      position: absolute;
      top: 168px;
      right: 0;
    }
    // .scroll-top {
    //   transform: translateY(-50%); /**上移元素**/
    //   -ms-transform: translateY(-50%);
    //   -webkit-transform: translateY(-50%);
    // }
  }
  .right {
    margin-left: 98px;
    margin-right: 129px;
    .title {
      display: flex;
      align-items: center;
      span {
        font-family: @font-family-regular;
        font-style: normal;
        font-weight: normal;
        font-size: 24px;
        line-height: 28px;
        color: #000000;
      }
      .line {
        margin-left: 28px;
        width: 226px;
        // border: 1px solid #000000;
        background: #000000;
        height: 1px;
      }
    }
    .des {
      margin-top: 28px;
      span {
        font-family: @font-family-bold;
        font-style: normal;
        font-weight: bold;
        font-size: 48px;
        line-height: 60px;
        letter-spacing: -0.02em;
        color: #000000;
      }
      .brand {
        font-family: @font-family-bold;
        background-image: @gradient-text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }
    .openyour {
      margin-top: 38px;
      font-style: normal;
      font-weight: normal;
      font-size: 20px;
      line-height: 30px;
      color: #000000;
      letter-spacing: -0.01em;
    }
    .view-gellery {
      margin-top: 50px;
      font-family: @font-family-bold;
      font-style: normal;
      font-weight: bold;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 0.02em;
      color: #2e53af;
      text-transform: uppercase;
      cursor: pointer;
      display: flex;
      .icon {
        display: flex;
        span {
          margin-right: 18px;
        }
      }
    }
  }
  @media screen and (max-width: 960px) {
    flex-direction: column;
    margin-top: 60px;
    padding-right: 16px;
    .left {
      width: 100%;
      .desktop-bg {
        display: none;
      }
      .mobile-bg {
        display: block;
      }
      .absolute-img {
        width: 93px;
        position: absolute;
        top: 75px;
        right: 0;
      }
    }
    .right {
      margin-left: 16px;
      margin-right: 0;
      .title {
        margin-top: 40px;
        span {
          font-size: 20px;
          line-height: 28px;
        }
        .line {
          width: 156px;
          margin-left: 18px;
        }
      }
      .des {
        span {
          font-size: 30px;
          line-height: 38px;
          font-family: @font-family-bold;
        }
      }
      .openyour {
        font-size: 16px;
        line-height: 26px;
        margin-top: 18px;
      }
      .view-gellery {
        font-size: 14px;
        line-height: 20px;
        margin-top: 28px;
      }
    }
  }
}
</style>
