<template>
  <div class="create-store-box">
    <div class="store-left">
      <div class="title">{{ $t("home.createStore.easySteps") }}</div>
      <ul>
        <li>{{ $t("home.createStore.step1") }}</li>
        <li>{{ $t("home.createStore.step2") }}</li>
        <li>{{ $t("home.createStore.step3") }}</li>
        <li>{{ $t("home.createStore.step4") }}</li>
        <li>{{ $t("home.createStore.step5") }}</li>
      </ul>
    </div>
    <div class="store-right loading-bg">
      <img :lazyLoadSrc="cds1" v-lazy-load-img />
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      cds1: `${process.env.VUE_APP_STORE_OSS_URL}/home/CDS1.webp`,
    };
  },
  methods: {},
};
</script>

<style lang="less" scoped>
.create-store-box {
  margin: 100px auto 0;
  background: @primary-color;
  padding: 154px 106px;
  box-sizing: border-box;
  font-size: 28px;
  line-height: 40px;
  color: #ffffff;
  display: flex;
  justify-content: flex-end;
  @media screen and( max-width:960px) {
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
    padding: 40px 15px;
    margin-top: 60px;
    font-size: 20px;
    line-height: 30px;
  }
  .store-left {
    padding-right: 100px;
    box-sizing: border-box;
    width: 50%;
    @media screen and( max-width:960px) {
      width: 100%;
      padding: 0;
    }
    .title {
      font-family: @font-family-bold;
      font-size: 48px;
      line-height: 60px;
      margin-bottom: 40px;
      @media screen and( max-width:960px) {
        font-size: 30px;
        line-height: 38px;
        margin-bottom: 20px;
      }
    }
    li {
      padding: 28px 0;
      box-sizing: border-box;
      border-bottom: 1px solid #ffffff;
      &:last-child {
        border: 0;
      }
      @media screen and( max-width:960px) {
        padding: 20px 0;
      }
    }
  }
  .store-right {
    //max-width: 720px;
    //max-height: 696px;
    padding-left: 100px;
    box-sizing: border-box;
    overflow: hidden;
    width: 50%;
    @media screen and( max-width:960px) {
      width: 100%;
      height: auto;
      max-height: fit-content;
      padding-left: 0;
      margin-bottom: 40px;
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 10px;
    }
  }
}
</style>
