<template>
  <div class="analytics-box">
    <div class="analytics-left">
      <div class="sub-title">
        {{ $t("home.analyticsDataModule.analyticsData") }}
        <div class="line"></div>
      </div>
      <div class="title" v-html="$t('home.analyticsDataModule.title')"></div>
      <div class="info">{{ $t("home.analyticsDataModule.info") }}</div>
    </div>
    <div class="analytics-right">
      <img
        :lazyLoadSrc="analyticImg"
        v-lazy-load-img
        src="../../../assets/img/loading.gif"
      />
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      analyticImg: `${process.env.VUE_APP_STORE_OSS_URL}/home/analyticsImg.webp`,
    };
  },
  methods: {},
};
</script>

<style lang="less" scoped>
/deep/.gradient-text {
  background-image: @gradient-text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.analytics-box {
  display: flex;
  justify-content: flex-start;
  margin-top: 100px;
  align-items: center;
  @media screen and( max-width:960px) {
    margin-top: 60px;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
  }
  .analytics-left {
    flex: 1;
    margin: 0 198px 0 106px;
    font-size: 24px;
    line-height: 28px;
    color: #000000;
    max-width: 705px;
    @media screen and( max-width:960px) {
      margin: 40px 0 0;
      padding: 0 16px;
      box-sizing: border-box;
    }
    .sub-title {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin-bottom: 28px;
      @media screen and( max-width:960px) {
        font-size: 20px;
        line-height: 28px;
      }
      .line {
        margin-left: 28px;
        height: 1px;
        width: 226px;
        background: #000000;
        @media screen and( max-width:960px) {
          margin-left: 18px;
          width: 150px;
        }
      }
    }
    .title {
      font-size: 48px;
      line-height: 60px;
      font-family: @font-family-bold;
      margin-bottom: 28px;
      @media screen and( max-width:960px) {
        font-size: 30px;
        line-height: 38px;
        margin-bottom: 18px;
      }
    }
    .info {
      font-size: 20px;
      line-height: 30px;
      @media screen and( max-width:960px) {
        font-size: 16px;
        line-height: 26px;
      }
    }
  }
  .analytics-right {
    max-width: 645px;
    max-height: 470px;
    overflow: hidden;
    @media screen and( max-width:960px) {
      max-width: 100%;
      padding-left: 40px;
      box-sizing: border-box;
      height: auto;
      max-height: fit-content;
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
}
</style>
