<template>
  <div class="domain-pixel-box">
    <div class="domain-pixel-left">
      <img
        class="img1 desktop-show loading-bg"
        :lazyLoadSrc="domain1"
        v-lazy-load-img
      />
      <img
        class="img1 mo-show loading-bg"
        :lazyLoadSrc="domain1Mo"
        v-lazy-load-img
      />
      <img class="img2" :src="domain2" ref="pixelAbsolute" />
      <img class="img3" :src="domain3" ref="storeUrlAbsolute" />
    </div>
    <div class="domain-pixel-right">
      <div class="sub-title">
        <div>{{ $t("home.domainPixelModule.domainPixel") }}</div>
        <div class="line"></div>
      </div>
      <div class="title" v-html="$t('home.domainPixelModule.title')"></div>
      <div class="info" v-html="$t('home.domainPixelModule.info')"></div>
    </div>
  </div>
</template>

<script>
import untils from "./until";
export default {
  mixins: [untils],
  data() {
    return {
      domain1: `${process.env.VUE_APP_STORE_OSS_URL}/home/domain1.webp`,
      domain1Mo: `${process.env.VUE_APP_STORE_OSS_URL}/home/domain1-mo.webp`,
      domain2: `${process.env.VUE_APP_STORE_OSS_URL}/home/domain2.png`,
      domain3: `${process.env.VUE_APP_STORE_OSS_URL}/home/domain3.png`,
    };
  },
  mounted() {
    const pixel = this.$refs.pixelAbsolute;
    const storeUrl = this.$refs.storeUrlAbsolute;
    pixel && this.initParallax(this.$el, pixel);
    storeUrl && this.initParallax(this.$el, storeUrl);
  },
  methods: {},
};
</script>

<style lang="less" scoped>
/deep/.gradient-text {
  background-image: @gradient-text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
/deep/.strong-text {
  font-family: @font-family-bold;
}
.desktop-show {
  @media screen and( max-width:960px) {
    display: none;
  }
}
.mo-show {
  display: none;
  @media screen and( max-width:960px) {
    display: block;
  }
}
.domain-pixel-box {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
  color: #000000;
  @media screen and( max-width:960px) {
    margin-top: 40px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }
  .domain-pixel-left {
    width: 820px;
    height: 663px;
    position: relative;
    box-sizing: border-box;
    @media screen and( max-width:960px) {
      max-width: 100%;
      height: auto;
      margin-bottom: 35px;
      padding-right: 5px;
      box-sizing: border-box;
    }
    .img1 {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    .img2 {
      position: absolute;
      top: 200px;
      right: -16px;
      width: 150px;
      height: 150px;
      @media screen and( max-width:960px) {
        width: 80px;
        height: 80px;
        right: -8px;
        top: 130px;
      }
    }
    .img3 {
      position: absolute;
      bottom: 20px;
      right: -85px;
      width: 355px;
      height: 217px;
      object-fit: contain;
      @media screen and( max-width:960px) {
        width: 200px;
        height: 122px;
        right: -15px;
        bottom: -18px;
      }
    }
  }
  .domain-pixel-right {
    flex: 1;
    margin-left: 111px;
    padding-right: 106px;
    box-sizing: border-box;
    @media screen and( max-width:960px) {
      margin: 0;
      padding: 0 15px;
    }
    .sub-title {
      font-size: 24px;
      line-height: 28px;
      color: #000000;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin-bottom: 28px;
      @media screen and( max-width:960px) {
        font-size: 20px;
        line-height: 28px;
        margin-bottom: 20px;
      }
      .line {
        margin-left: 28px;
        height: 1px;
        width: 180px;
        background: #000000;
        @media screen and( max-width:960px) {
          margin-left: 18px;
          width: 175px;
        }
      }
    }
    .title {
      font-size: 48px;
      line-height: 60px;
      font-family: @font-family-bold;
      margin-bottom: 28px;
      @media screen and( max-width:960px) {
        font-size: 30px;
        line-height: 38px;
        margin-bottom: 18px;
      }
    }
    .info {
      font-size: 20px;
      line-height: 30px;
      @media screen and( max-width:960px) {
        font-size: 16px;
        line-height: 26px;
      }
    }
  }
}
</style>
