<template>
  <div class="banner">
    <div class="left-side">
      <div class="title">
        <span>{{ $t("banner.elevate") }}</span>
        <span class="brand"> Brand </span>
        <span>{{ $t("banner.elevatespan") }}</span>
      </div>
      <div class="title-sub" v-if="showSubtitle">
        {{ $t("banner.titleSub") }}
      </div>
      <div class="des">
        <span>{{ $t("banner.des") }}</span>
        <span class="bold">{{ $t("banner.free") }}</span>
        <span>{{ $t("banner.des2") }}</span>
      </div>
      <!-- <div class="input-sub" v-if="!userMsg"> -->
      <div class="input-sub">
        <el-input
          v-model="enterNumber"
          type="number"
          :placeholder="$t('banner.enter')"
          class="input-box"
        ></el-input>
        <div class="btn" @click="openSignup">
          <div>{{ $t("banner.startnow") }}</div>
        </div>
      </div>
      <div class="error-text" v-if="showErrorText">
        {{ $t("banner.errorText") }}
      </div>
      <!-- <div class="btn-dashboard" v-if="userMsg" @click="gotoDashboard">
        <div>{{ $t("banner.goToDashboard") }}</div>
      </div> -->
    </div>
    <div class="right-side">
      <img :src="homeBanner" alt="" class="desktop-img" />
      <img :src="moHomeBanner" alt="" class="mobile-img" />
    </div>
  </div>
</template>

<script>
import resetUrl from "../../../utils/resetUrl";
import { TRACKER_LOGIN_QUERY_STR } from "@/constant";

export default {
  data() {
    return {
      enterNumber: "",
      url: process.env.VUE_APP_BASE_URL,
      userMsg: null,
      homeBanner: `${process.env.VUE_APP_STORE_OSS_URL}/home/home-banner.webp`,
      moHomeBanner: `${process.env.VUE_APP_STORE_OSS_URL}/home/home-mobanner.webp`,
      showErrorText: false,
    };
  },
  computed: {
    showSubtitle() {
      return this.$store.state.language === "en" ? true : false;
    },
    userInfo() {
      return this.$store.state.userInfo;
    },
    trackParams() {
      return this.$store.state.trackParams;
    },
  },
  methods: {
    openSignup() {
      if (this.enterNumber !== "") {
        if (this.enterNumber.startsWith("8")) {
          this.$logEvent("ds-s:click start create store", {
            phone_number: this.enterNumber,
            source: "homepage",
          });
          this.showErrorText = false;
          window.open(
            resetUrl(
              `${process.env.VUE_APP_BASE_URL}/common/signup?url=${this.enterNumber}&${TRACKER_LOGIN_QUERY_STR}`,
              this.trackParams
            )
            // "_self"
          );
        } else {
          this.showErrorText = true;
        }
      }
    },
    gotoDashboard() {
      window.open(
        resetUrl(
          `${process.env.VUE_APP_BASE_URL}/store/dashboard`,
          this.trackParams
        ),
        "_self"
      );
    },
  },
  watch: {
    userInfo: {
      handler(val) {
        if (val) {
          this.userMsg = val;
        } else {
          this.userMsg = JSON.parse(
            localStorage.getItem("storeHomeVuex")
          ).userInfo;
        }
      },
    },
  },
};
</script>

<style lang="less" scoped>
.banner {
  display: flex;
  justify-content: space-between;
  padding-top: 53px;
  padding-bottom: 59px;
  background: @bg-color;
  border-radius: 0px 0px 60px 60px;
  .left-side {
    padding: 0 106px;
    .title {
      margin-top: 20px;
      span {
        font-family: @font-family-bold;
        font-style: normal;
        font-weight: bold;
        font-size: 56px;
        line-height: 72px;
        letter-spacing: -0.02em;
        color: #000000;
      }
      .brand {
        background-image: @gradient-text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }
    .title-sub {
      font-size: 56px;
      line-height: 72px;
      color: #727272;
      font-family: @font-family-bold;
      @media screen and (max-width: 960px) {
        font-size: 32px;
        line-height: 48px;
      }
    }
    .des {
      margin-top: 28px;
      span {
        font-family: @font-family-regular;
        font-style: normal;
        font-weight: normal;
        font-size: 20px;
        line-height: 30px;
        letter-spacing: -0.01em;
        color: #000000;
      }
      .bold {
        font-family: @font-family-bold;
        font-weight: bold;
      }
    }
    .input-sub {
      margin-top: 28px;
      display: flex;
      .input-box {
        width: 301px !important;
        /deep/.el-input__inner {
          border: 1px solid @primary-color;
          box-sizing: border-box;
          border-radius: 12px 0 0 12px;
          font-family: @font-family-regular;
          font-style: normal;
          font-weight: normal;
          font-size: 20px;
          line-height: 30px;
          color: #6b7280;
          height: 58px;
        }
      }
      .btn {
        background: @primary-color;
        border-radius: 12px;
        margin-left: -10px;
        font-family: @font-family-bold;
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        line-height: 58px;
        letter-spacing: 0.02em;
        text-transform: uppercase;
        color: #ffffff;
        z-index: 2;
        height: 58px;
        border: 1px solid @primary-color;
        box-sizing: border-box;
        width: 137px;
        position: relative;
        text-align: center;
        cursor: pointer;
      }
      .btn::after {
        content: "";
        display: block;
        width: 0;
        height: 100%;
        background: #006bd5;
        position: absolute;
        border-radius: 8px;
        left: 0;
        top: 0;
        z-index: -1;
        transition: 0.4s;
      }
      .btn:hover::after {
        width: 100%;
        z-index: 1;
        clear: both;
      }
      .btn:hover {
        color: #fff;
        div {
          z-index: 4;
          position: absolute;
          top: 0;
          left: 0;
          bottom: 0;
          right: 0;
        }
      }
    }
    .error-text {
      font-family: @font-family-regular;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      margin-top: 10px;
      color: #ff2626;
    }
    .btn-dashboard {
      margin-top: 28px;
      background: @primary-color;
      border-radius: 12px;
      font-size: 14px;
      line-height: 58px;
      text-transform: uppercase;
      color: #ffffff;
      font-family: @font-family-bold;
      height: 58px;
      box-sizing: border-box;
      display: inline-block;
      position: relative;
      width: 211px;
      text-align: center;
      @media screen and (max-width: 960px) {
        width: 180px;
        font-size: 12px;
        line-height: 50px;
        height: 50px;
        margin-top: 24px;
      }
    }
    .btn-dashboard::after {
      content: "";
      display: block;
      width: 0;
      height: 100%;
      background: #006bd5;
      position: absolute;
      border-radius: 8px;
      left: 0;
      top: 0;
      z-index: -1;
      transition: 0.4s;
    }
    .btn-dashboard:hover::after {
      width: 100%;
      z-index: 1;
      clear: both;
    }
    .btn-dashboard:hover {
      color: #fff;
      div {
        z-index: 4;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
      }
    }
  }
  .right-side {
    .mobile-img {
      display: none;
    }
  }
  @media screen and( max-width:960px) {
    display: flex;
    flex-direction: column;
    padding-top: 20px;
    .left-side {
      padding: 0 15px;
      .title {
        margin-top: 0;
        span {
          font-size: 36px;
          line-height: 50px;
        }
      }
      .des {
        width: 100%;
        margin-top: 20px;
        span {
          font-size: 16px;
          line-height: 26px;
        }
      }
      .input-sub {
        margin-top: 28px;
        display: flex;
        .input-box {
          width: 100% !important;
          /deep/.el-input__inner {
            height: 50px;
            font-size: 16px;
            line-height: 26px;
          }
        }
        .btn {
          font-size: 12px;
          line-height: 50px;
          height: 50px;
        }
      }
    }
    .right-side {
      padding: 0 0 0 15px;
      margin-top: 30px;
      .mobile-img {
        display: block;
        width: 100%;
        height: auto;
        margin-right: -1px;
      }
      .desktop-img {
        display: none;
      }
    }
  }
}
</style>
